import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { IPropsStore, PageFeatureConfigSymbol, Props } from '@wix/thunderbolt-symbols'
import type { ISamePageAnchorHrefHandler, ISamePageAnchorPropsResolver, ScrollToAnchorPageConfig } from './types'
import { name, SamePageAnchorPropsResolverSymbol } from './symbols'

export const SamePageAnchorHrefHandler = withDependencies(
	[named(PageFeatureConfigSymbol, name), Props, SamePageAnchorPropsResolverSymbol],
	(
		{ compsToUpdate }: ScrollToAnchorPageConfig,
		propsStore: IPropsStore,
		{ getPropsOverrides }: ISamePageAnchorPropsResolver
	): ISamePageAnchorHrefHandler => {
		return {
			handle: () => {
				if (compsToUpdate.length > 0) {
					const updatedProps = compsToUpdate.reduce((propsToUpdate, compToUpdateData) => {
						const propsOverrides = getPropsOverrides(compToUpdateData)

						return {
							...propsToUpdate,
							...propsOverrides,
						}
					}, {})

					propsStore.update(updatedProps)
				}
			},
		}
	}
)
