import { IPageWillMountHandler } from '@wix/thunderbolt-symbols'
import { withDependencies } from '@wix/thunderbolt-ioc'
import { SamePageAnchorHrefHandlerSymbol } from './symbols'
import { ISamePageAnchorHrefHandler } from './types'

export const SamePageAnchorHrefUpdater = withDependencies(
	[SamePageAnchorHrefHandlerSymbol],
	({ handle }: ISamePageAnchorHrefHandler): IPageWillMountHandler => ({
		pageWillMount: async () => {
			handle()
		},
	})
)
