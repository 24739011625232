import { withDependencies, multi } from '@wix/thunderbolt-ioc'
import { IPageWillMountHandler, AppStructure, IPageWillUnmountHandler } from '@wix/thunderbolt-symbols'
import { PageStructureJsonSymbol } from 'feature-pages'
import type { ViewerComponent, ComponentWillMount, ComponentsStore } from './types'
import { ComponentWillMountSymbol, ComponentsStoreSymbol } from './symbols'
import { groupByMultipleComponentTypes } from './groupByMultipleComponentTypes'

const componentWillMountOnPageWillMount = (
	componentWillMountArray: Array<ComponentWillMount<ViewerComponent>>,
	componentsStore: ComponentsStore,
	pageStructure: AppStructure
): IPageWillMountHandler & IPageWillUnmountHandler => {
	let onPageWillUnmount: () => void = () => {}
	return {
		async pageWillMount() {
			const componentWillMountByCompType = groupByMultipleComponentTypes(componentWillMountArray)

			const componentWillUnmount = await Promise.all(
				Object.entries(pageStructure)
					.map(([compId, { componentType }]) => {
						if (!componentWillMountByCompType[componentType]) {
							return null
						}

						const component = componentsStore.get<ViewerComponent>(compId)
						const all = componentWillMountByCompType[componentType].map(({ componentWillMount }) =>
							componentWillMount(component)
						)

						return all
					})
					.flat()
			)

			onPageWillUnmount = () => componentWillUnmount.forEach((x) => x && x())
		},
		pageWillUnmount: () => {
			onPageWillUnmount()
		},
	}
}

export const ComponentWillMountFactory = withDependencies(
	[multi(ComponentWillMountSymbol), ComponentsStoreSymbol, PageStructureJsonSymbol],
	componentWillMountOnPageWillMount
)
